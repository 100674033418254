import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import {
  Button,
  Space,
  Form,
  Input,
  Row,
  Col,
  Radio,
  Select,
  Spin,
  Empty,
} from 'antd';
import i18n from 'i18n';
import LOCALS from 'commons/locals';
import { consultingSource } from 'constants/RecyclingConsignment';
import RenderLabel from 'components/recycling-consignment/render-label';
import { umsSearchMember, getMemberList } from 'apis/ums';
import type { FormInstance } from 'antd/es/form';
import {
  OmsRecycleOrderSNSCreateDTO,
  OmsRecycleOrderCreateDTO,
} from 'types/oms';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { useAppSelector } from 'store/hooks';
import { SOCIAL_MEDIA_OPTION_LIST } from 'commons/options';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import { useSelector } from 'react-redux';
import { cityList } from 'constants/appointment-management';
import { UserAddOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import CreateMemberDrawer from 'components/create-member-drawer';
import { useToggle } from 'react-use';
import { UmsMember } from 'types/ums';
import { debounce } from 'lodash-es';

interface Props {
  form: FormInstance<OmsRecycleOrderSNSCreateDTO | OmsRecycleOrderCreateDTO>;
  onChange: (
    data: (OmsRecycleOrderSNSCreateDTO | OmsRecycleOrderCreateDTO) & {
      user?: UserType;
    }
  ) => void;
  type: 'intention' | 'contract';
}

export interface UserType {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  username?: string;
  memberId?: number;
  city?: string;
  socialName?: string;
  socialAccount?: string;
  country?: string;
  detailAddress?: string;
  postCode?: string;
}

const UserInfo = ({ form, onChange, type }: Props) => {
  const location = useLocation();
  const [searchLoading, setSearchLoading] = useState(false);
  const [userList, setUserList] = useState<UmsMember[]>([]);
  const [user, setUser] = useState<UserType>({});
  const [createMemberOpen, toggleCreateMemberOpen] = useToggle(false);

  const { countryOptions } = useAppSelector(selectGlobalInfo);
  const { shop } = useSelector(selectUserInfo);

  useEffect(() => {
    form.setFieldValue('storeId', shop || 1);
  }, [form, shop]);

  const handleUserData = (data: UmsMember) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      id,
      countryCode,
      socialName,
      country,
      city,
      detailAddress,
      socialAccount,
    } = data;
    const phone_ = phone ? `${countryCode} ${phone}` : '';
    const phone1 = phone_ ? `${phone_}` : '';

    const social = SOCIAL_MEDIA_OPTION_LIST.find(
      (d) => d.value === socialName
    )?.label;

    return {
      email,
      username: `${firstName || ''} ${lastName || ''}`,
      phone: phone1,
      memberId: id,
      socialName: social,
      socialAccount,
      country,
      city,
      detailAddress,
    };
  };

  const debouncedHandleSearch = useMemo(() => {
    return debounce((keyword: string) => {
      keyword = keyword.trim();
      if (!keyword) {
        return;
      }
      setSearchLoading(true);
      getMemberList({ keyword, pageNum: 1, pageSize: 100 })
        .then((data) => {
          setUserList(data.data.list);
        })
        .catch(() => {})
        .finally(() => {
          setSearchLoading(false);
        });
    }, 555);
  }, []);

  const getMemberInfo = useCallback(
    (keyword: string) => debouncedHandleSearch(keyword),
    [debouncedHandleSearch]
  );

  useEffect(() => {
    if (location.state?.memberEmail) {
      umsSearchMember(location.state?.memberEmail)
        .then((data) => {
          if (data.data) {
            const t = handleUserData(data.data);
            setUser(t);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [location.state?.memberEmail]);

  const storeId = Form.useWatch('storeId', {
    form,
    preserve: true,
  });
  useEffect(() => {
    if (user.memberId) {
      onChange({ ...user, storeId, user });
      form.setFieldValue('keyword', user.email);
    }
  }, [form, onChange, user, storeId]);

  // 详细信息
  const getDescItems = useMemo(() => {
    if (user) {
      const country = countryOptions.find((d) => d.value === user.country);

      return [
        {
          key: '3',
          label: i18n.t('name'),
          children: user.username,
        },
        {
          key: '1',
          label: i18n.t('member_id'),
          children: user.memberId,
        },
        {
          key: '2',
          label: i18n.t('phone_number'),
          children: user.phone,
        },
        {
          key: '4',
          label: i18n.t('country_region'),
          children: country?.label,
        },
        {
          key: '5',
          label: i18n.t('detail_address'),
          children: user.detailAddress,
        },
        {
          key: '6',
          label: user.socialName,
          children: user.socialAccount,
        },
      ];
    }
    return [];
  }, [user, countryOptions]);

  return (
    <div>
      <Form
        name="basic"
        style={{}}
        initialValues={{ storeId: shop || 1 }}
        autoComplete="off"
        form={form}
        className={`${styles.step0} renderLabel`}
        onValuesChange={(_, allValues) => {
          let payload = { ...allValues };
          onChange(payload);
        }}
      >
        <Row gutter={[0, 0]}>
          <Col span={20}>
            <Form.Item
              rules={[{ required: true }]}
              name="keyword"
              label={i18n.t('phone_or_email')}
              className="mb-2"
            >
              <Select
                showSearch
                allowClear
                placeholder={i18n.t('phone_or_email')!}
                filterOption={false}
                notFoundContent={
                  searchLoading ? <Spin size="small" /> : <Empty />
                }
                onSearch={getMemberInfo}
                onChange={(memberId: UmsMember['id']) => {
                  const target = userList.find((i) => i.id === memberId);
                  if (target) {
                    const t = handleUserData(target);
                    setUser(t);
                  }
                }}
                options={userList.map((i) => {
                  return {
                    ...i,
                    value: i.id,
                    label: `${i.showName}(${i.email})`,
                  };
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item className="mb-2">
              <Space className="ml-2">
                <Button
                  type="primary"
                  onClick={() => {
                    toggleCreateMemberOpen(true);
                  }}
                  icon={<UserAddOutlined />}
                >
                  {i18n.t('add_member')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item>
              <div className="grid grid-cols-3 gap-2 bg-[#F9F9F9] my-4 p-6">
                {getDescItems.map((d) => (
                  <div className="flex" key={d.key}>
                    <div className="text-[#666] mr-2">
                      {d.label ? `${d.label}：` : ''}
                    </div>
                    <div className="text-[#222]">{d.children}</div>
                  </div>
                ))}
              </div>
            </Form.Item>
          </Col>
        </Row>

        {type === 'intention' && (
          <>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <RenderLabel width={130} required>
                      {i18n.t('consulting_source')}
                    </RenderLabel>
                  }
                  name="createdFrom"
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    options={consultingSource}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <RenderLabel width={130}>{i18n.t(LOCALS.note)}</RenderLabel>
                  }
                  name="memberRemark"
                >
                  <Input.TextArea maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {type === 'contract' && (
          <>
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <RenderLabel width={80} required>
                      {i18n.t('shop')}
                    </RenderLabel>
                  }
                  name="storeId"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={cityList} disabled={Boolean(shop)} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>

      <CreateMemberDrawer
        open={createMemberOpen}
        onClose={() => toggleCreateMemberOpen(false)}
        setUmsMember={(data: UmsMember) => {
          setUser(handleUserData(data));
        }}
      />
    </div>
  );
};

export default UserInfo;
