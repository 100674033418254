import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Select, Radio, Input, Divider } from 'antd';
import RenderLabel from 'components/recycling-consignment/render-label';
import {
  PaymentMethod,
  MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP,
  MEMBER_PAYMENT_ACCOUNT_TYPE_MAP,
} from 'constants/RecyclingConsignment';
import type { FormInstance } from 'antd/es/form';
import {
  OmsRecycleOrderSNSCreateDTO,
  OmsRecycleOrderCreateDTO,
} from 'types/oms';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { MallCity } from 'types/home';
import { getCityListByCountryCode } from 'apis/home';
import i18n from '../../../../../i18n';
import LOCALS from '../../../../../commons/locals';
import { UserType } from '../user-info/index';
import { SelectOption } from 'types/base';

export const Options = [
  { label: '选择地址', value: 1 },
  { label: '手填地址', value: 2 },
];

interface Props {
  form: FormInstance<any>;
  onChange: (
    data: OmsRecycleOrderSNSCreateDTO | OmsRecycleOrderCreateDTO
  ) => void;
  type: string;
  orderType?: number;
  userInfo?: UserType;
}

const PayInfo = ({ form, onChange, orderType, userInfo }: Props) => {
  const [payMethod, setPayMethod] = useState<number>(2); // 打款方式

  const [payAccount, setPayAccount] = useState<number>(
    MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.JP
  ); // 打款账户
  const [transferType, setTransferType] = useState<number>(
    MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK
  ); // 转账类型
  const [country, setCounty] = useState<string>('JPN');

  const { countryOptions, countryCodeOptions } =
    useAppSelector(selectGlobalInfo);
  const [cityList, setCityList] = useState<MallCity[]>([]);

  const SelectFilter = useCallback(
    (input: string, option: SelectOption | undefined) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    []
  );

  useEffect(() => {
    setPayAccount(MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.JP);
    setTransferType(MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK);
    form.setFieldsValue({
      payAccount: MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.JP,
      memberPaymentAccountSubType: MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK,
    });
  }, [payMethod, form]);

  useEffect(() => {
    setTransferType(MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK);
    form.setFieldsValue({
      memberPaymentAccountSubType: MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK,
    });
  }, [payAccount, form]);

  useEffect(() => {
    setPayMethod(2);
    form.setFieldValue('settlementType', 2);
  }, [orderType, form]);

  const getCityList = useCallback(
    (country: string) => {
      form.setFieldValue('city', '');
      setCityList([]);
      getCityListByCountryCode(country).then((res) => {
        setCityList(res.data.cityList || []);
      });
    },
    [form]
  );

  useEffect(() => {
    if (userInfo) {
      const phone: string[] | undefined = userInfo?.phone?.split(' ');
      const [areaCode, postPhone] = phone || ['', ''];
      form.setFieldsValue({
        name: userInfo?.username,
        areaCode,
        postPhone,
        postCode: userInfo?.postCode,
        country: userInfo?.country,
        city: userInfo?.city,
        detailAddress: userInfo?.detailAddress,
      });
      if (userInfo?.country) getCityList(userInfo?.country);
    }
  }, [form, getCityList, userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.city && cityList.length) {
      const city = cityList.find((d) => d.id === Number(userInfo.city));
      form.setFieldValue('city', city?.code);
    }
  }, [form, cityList, userInfo]);

  return (
    <Form
      name="basic"
      className="renderLabel"
      initialValues={{
        settlementType: payMethod,
        payAccount,
        memberPaymentAccountSubType: transferType,
      }}
      autoComplete="off"
      form={form}
      onValuesChange={(_, allValues) => {
        const { settlementType, payAccount, ...rest } = allValues;
        const memberPaymentAccountType = payAccount;
        let payload: OmsRecycleOrderCreateDTO = {
          settlementType,
          memberPaymentAccountType,
        };
        if (orderType === 1) {
          const {
            city,
            detailAddress,
            name,
            postCode,
            areaCode,
            country,
            postPhone,
          } = allValues;
          payload = {
            city,
            country,
            detailAddress,
            name,
            postPhone:
              areaCode && postPhone ? `${areaCode} ${postPhone}` : undefined,
            postCode,
            settlementType,
            memberPaymentAccountType,
          };
        }
        if (settlementType === 2) {
          payload = {
            settlementType,
            memberPaymentAccountType,
            ...rest,
            ...payload,
          };
        }
        onChange(payload);
        console.log('🚀  PayInfo  payload:', payload);
      }}
    >
      <Form.Item>
        <Row gutter={[48, 0]}>
          <Col span={12}>
            <Form.Item
              label={<RenderLabel required>{i18n.t('name')}</RenderLabel>}
              rules={[
                {
                  required: true,
                },
              ]}
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <RenderLabel required>{i18n.t('phone_number')}</RenderLabel>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    name="areaCode"
                    style={{ marginBottom: 0 }}
                  >
                    <Select options={countryCodeOptions} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    name="postPhone"
                    style={{ marginBottom: 0 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 0]}>
          <Col span={12}>
            <Form.Item
              label={<RenderLabel>{i18n.t('zip_code')}</RenderLabel>}
              name="postCode"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<RenderLabel required>{i18n.t('location')}</RenderLabel>}
              name={'country'}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={countryOptions}
                onChange={(e) => getCityList(e)}
                showSearch
                filterOption={SelectFilter}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 0]}>
          <Col span={12}>
            <Form.Item
              label={
                <RenderLabel required>
                  {i18n.t('state_province_city')}
                </RenderLabel>
              }
              name="city"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={cityList.map(({ name, code }) => {
                  return {
                    value: code,
                    label: name,
                  };
                })}
                showSearch
                filterOption={SelectFilter}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <RenderLabel required>{i18n.t('detail_address')}</RenderLabel>
              }
              name="detailAddress"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Row gutter={[48, 0]}>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>

      <Row gutter={[48, 0]}>
        <Col span={24}>
          <Form.Item
            label={
              <RenderLabel required>
                {i18n.t(LOCALS.payment_method)}
              </RenderLabel>
            }
            name="settlementType"
            rules={[{ required: true }]}
          >
            <Select
              options={PaymentMethod}
              onChange={(e) => {
                setPayMethod(e);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {payMethod === 2 && (
        <>
          <Row gutter={[48, 0]}>
            <Col span={24}>
              <Form.Item
                label={
                  <RenderLabel required>
                    {i18n.t('receiving_account')}
                  </RenderLabel>
                }
                name="payAccount"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    setPayAccount(e.target.value);
                  }}
                >
                  <Radio value={MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.HONG_KONG}>
                    {i18n.t('hong_kong_account')}
                  </Radio>
                  <Radio value={MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.OVERSEA}>
                    {i18n.t('overseas_account')}
                  </Radio>
                  <Radio value={MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.JP}>
                    {i18n.t('japanese_account')}
                  </Radio>
                  <Radio value={MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.SG}>
                    {i18n.t('singapore_account')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {/* 香港 */}
          {payAccount === MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.HONG_KONG && (
            <>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('payment_type')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountSubType"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      onChange={(e) => setTransferType(e.target.value)}
                    >
                      <Radio value={MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK}>
                        {i18n.t('bank_transfer')}
                      </Radio>
                      <Radio value={MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.FPS}>
                        {i18n.t('fps')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {transferType === MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK && (
                <>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('bank_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentBankName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('bank_code')}
                          </RenderLabel>
                        }
                        name="memberPaymentBankNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_number')}
                          </RenderLabel>
                        }
                        name="memberPaymentAccountNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_holder_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentAccountName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {transferType === MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.FPS && (
                <>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>{i18n.t('number')}</RenderLabel>
                        }
                        name="memberPaymentFpsNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_holder_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentFpsAccountName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {/* 海外 */}
          {payAccount === MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.OVERSEA && (
            <>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('country_region')}
                      </RenderLabel>
                    }
                    name="memberPaymentBankCountry"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={countryOptions}
                      onChange={(e) => setCounty(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>{i18n.t('bank_name')}</RenderLabel>
                    }
                    name="memberPaymentBankName"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('bank_address')}
                      </RenderLabel>
                    }
                    name="memberPaymentBankAddress"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={<RenderLabel required>SWIFT Code</RenderLabel>}
                    name="memberPaymentSwiftCode"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('account_number')}/IBAN
                      </RenderLabel>
                    }
                    name="memberPaymentAccountNo"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {country === 'USA' && (
                <Row gutter={[48, 0]}>
                  <Col span={24}>
                    <Form.Item
                      label={<RenderLabel required>Routing No.</RenderLabel>}
                      name="memberPaymentRoutingNo"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('account_holder_name')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountName"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('account_holder_address')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountAddress"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {/* 日本账户 */}
          {payAccount === MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.JP && (
            <>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>{i18n.t('bank_name')}</RenderLabel>
                    }
                    name="memberPaymentBankName"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('bank_address')}
                      </RenderLabel>
                    }
                    name="memberPaymentBankAddress"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('account_number')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountNo"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('account_holder_name')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountName"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={<RenderLabel>{i18n.t('identifier')}</RenderLabel>}
                    name="memberPaymentKiGo"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={<RenderLabel>{i18n.t('number')}</RenderLabel>}
                    name="memberPaymentBangGo"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {/* 新加坡账户 */}
          {payAccount === MEMBER_PAYMENT_ACCOUNT_TYPE_MAP.SG && (
            <>
              <Row gutter={[48, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <RenderLabel required>
                        {i18n.t('payment_type')}
                      </RenderLabel>
                    }
                    name="memberPaymentAccountSubType"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      onChange={(e) => setTransferType(e.target.value)}
                    >
                      <Radio value={MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK}>
                        {i18n.t('bank_transfer')}
                      </Radio>
                      <Radio
                        value={MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.PAY_NOW}
                      >
                        {i18n.t('paynow')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {transferType === MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.BANK && (
                <>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('bank_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentBankName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('bank_code')}
                          </RenderLabel>
                        }
                        name="memberPaymentBankNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={<RenderLabel required>SWIFT Code</RenderLabel>}
                        name="memberPaymentSwiftCode"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_number')}
                          </RenderLabel>
                        }
                        name="memberPaymentAccountNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_holder_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentAccountName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_holder_address')}
                          </RenderLabel>
                        }
                        name="memberPaymentAccountAddress"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('contact_number')}
                          </RenderLabel>
                        }
                        name="memberPaymentBankPhone"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {transferType === MEMBER_PAYMENT_ACCOUNT_SUB_TYPE_MAP.PAY_NOW && (
                <>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>{i18n.t('paynow')}</RenderLabel>
                        }
                        name="memberPaymentPayNowNo"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <RenderLabel required>
                            {i18n.t('account_holder_name')}
                          </RenderLabel>
                        }
                        name="memberPaymentPayNowAccountName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </Form>
  );
};

export default PayInfo;
